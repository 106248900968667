import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { device } from "../../utils";
import { Title, Button, Section } from "../../components/Core";

const StyledCTAButton = styled(Button)`
  padding: 12px 18px;
  white-space: normal;
  transform: none;
  @media ${device.md} {
    padding: 0.85rem 1.75rem;
  }
`;

const CTA = (props) => (
  <>
    <Container>
      <Section
        bg="ash"
        py="32px"
        px={4}
        style={{ borderRadius: 10, marginTop: -62 }}
        data-aos="flip-up"
        data-aos-duration="750"
        data-aos-once="true"
      >
        <Row>
          <Col lg="5">
            <div>
              <Title variant="card" size="40px" color="light" className="mb-0">
                {props.cta1}
              </Title>
              <Title
                variant="card"
                size="40px"
                color="lightShade"
                className="mb-0"
              >
                {props.cta2}
              </Title>
            </div>
          </Col>
          <Col
            lg="7"
            className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0"
          >
            <div>
              <a
                href={
                  "https://" +
                  props.url +
                  "/manage/new" +
                  (props.affiliate ? "/?ref=" + props.affiliate : "")
                }
                target="_blank"
                rel="noopener noreferrer"
                id="clickNewTournament"
              >
                <StyledCTAButton
                  bg="white"
                  color="dark"
                  px={["0.75rem", "1.75rem"]}
                >
                  <i
                    className="fas fa-trophy d-none d-md-block"
                    style={{ marginRight: 10 }}
                  />
                  {props.cta}
                </StyledCTAButton>
              </a>
            </div>
          </Col>
        </Row>
      </Section>
    </Container>
  </>
);

export default CTA;
