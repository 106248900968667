import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import { Title, Section, Box, Text } from "../../components/Core";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import { breakpoints } from "../../utils";

const SliderStyled = styled(Slider)`
  margin: 0 -10px;
  .slick-dots {
    position: relative;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const StyledBox = styled(Box)`
  &:focus {
    outline: none;
  }
`;

const QuoteImage = styled.div`
  width: 41px;
  height: auto;
  margin-right: 18px;
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="border"
    p="30px"
    mx={["10px", "10px", "20px"]}
    borderRadius={10}
    className={`${className}`}
    {...rest}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <QuoteImage>
        <PreviewCompatibleImage
          imageInfo={{
            image: image,
          }}
        />
      </QuoteImage>

      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
);

const Testimonial = (props) => {
  const slickSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Section bg="#f7f7fb">
        <Container>
          <Row className="justify-content-md-center">
            <Col md="9" lg="10" className="text-md-center">
              <Box className="text-md-center">
                <Title>{props.heading}</Title>
              </Box>
              <Text mb={4}>{props.subheading}</Text>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col lg="11">
              <SliderStyled {...slickSettings}>
                {props.quotes.map((quote, i) => (
                  <StyledBox key={i}>
                    <ContentCard
                      name={quote.author}
                      company={quote.company}
                      image={quote.image}
                    >
                      {quote.text}
                    </ContentCard>
                  </StyledBox>
                ))}
              </SliderStyled>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Testimonial;
