import React from "react";
import CaseRoll from "../../components/CaseRoll";
import styled from "styled-components";
import { Section, Title, Text, Button } from "../../components/Core";
import { langKeyDefault } from "../../../buildVariables";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "gatsby";

const ReadMore = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;
`;

const CasesSection = (props) => {
  const intl = useIntl();
  return (
    <>
      <Section>
        <div className="pt-5 pt-lg-0"></div>
        <Container>
          <Row className="justify-content-md-center text-md-center">
            <Col lg="8">
              <Title>
                <FormattedMessage
                  id="cases.heading"
                  defaultMessage="Business cases"
                />
              </Title>
              <Text>
                <FormattedMessage
                  id="cases.subheading_short"
                  defaultMessage="We're proud to be able to collaborate with great partners who share our passion for sports and eSports"
                />
              </Text>
            </Col>
          </Row>

          <div className="pt-3"></div>
          <CaseRoll
            cta={intl.formatMessage({
              id: "cases.cta",
              defaultMessage: "Learn more",
            })}
            cases={props.cases}
          />
          <ReadMore className="text-decoration-none">
            <Link
              to={
                "/" +
                (props.langKey === langKeyDefault ? "" : props.langKey + "/") +
                "cases"
              }
            >
              <Button variant="outline" color="dark">
                {intl.formatMessage({
                  id: "cases.view_all",
                  defaultMessage: "View all business cases",
                })}
              </Button>
            </Link>
          </ReadMore>
        </Container>
      </Section>
    </>
  );
};

export default CasesSection;
