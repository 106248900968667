import React from "react";
import { Col } from "react-bootstrap";
import ContentCard from "./ContentCard";

const BlogRoll = (props) => {
  const { edges: posts } = props.blogs;

  return (
    <>
      {posts &&
        posts.map(({ node: post }) => (
          <Col lg="6" className="mb-5" key={post.fields.slug}>
            <ContentCard
              img={post.frontmatter.featuredimage}
              imgAlt={`featured image post ${post.frontmatter.title}`}
              date={post.frontmatter.date}
              title={post.frontmatter.title}
              url={post.fields.slug}
              cta={props.cta}
            >
              {post.frontmatter.description}
            </ContentCard>
          </Col>
        ))}
    </>
  );
};

export default BlogRoll;
