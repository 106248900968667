import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Button } from "../../components/Core";
import { device } from "../../utils";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
// import { FormattedMessage } from "react-intl";
import iconWebsite from "../../assets/image/website.png";
import iconApp from "../../assets/image/app.png";
import iconSlideshow from "../../assets/image/present.png";

const ContentCard = styled(Box)`
  padding: 20px;
  transition: background 1s ease;
  border-radius: 15px;

  &.not-selected:hover {
    cursor: pointer;
    background: #f7f7fb;
  }
  &.selected {
    background: #f7f7fb;
  }
`;

const ImageContainer = styled.div`
  display: none;
  filter: ${({ theme }) => `drop-shadow(0 52px 54px ${theme.colors.shadow})`};
  @media ${device.lg} {
    margin-right: 5%;
  }
  &.selected {
    display: block;
  }
`;
const PresentationImage = styled.div`
  transition: 0.4s;
  @media ${device.lg} {
    ${ImageContainer}:hover & {
      filter: brightness(80%);
    }
  }
`;

const PresentationImageVertical = styled.div`
  transition: 0.4s;
  flex: 2;
  @media ${device.lg} {
    ${ImageContainer}:hover & {
      filter: brightness(80%);
    }
  }
`;

const TopCard = styled(Box)`
  height: fit-content;
  box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
`;

const BtnContainer = styled(Box)`
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 7px;
  opacity: 0;
  transition: 0.4s opacity, 0.4s margin-top;
  height: 40px;
  font-size: 16px;
  text-align: center;

  @media ${device.lg} {
    ${ImageContainer}:hover & {
      opacity: 1;
      margin-top: -13px;
    }
    button {
      font-size: 16px;
      padding: 0.85rem 1.75rem;
    }
  }
`;

// const StyledButton = styled.div`
//   font-size: 13px;
//   font-weight: 700;
//   border-radius: 15px;
//   padding: 4px 12px;
//   min-width: 0;
//   background-color: #f04037;
//   color: white;
//   margin-bottom: 6px;
//   margin-left: 10px;
//   display: flex;
//   align-items: center;
// `;

const StyledBox = styled(Box)`
  min-width: 40px;
`;

class Presentation extends React.Component {
  state = {
    url: "",
    screenWidth: 0,
    selectedUSP: 0,
  };

  setSelectedUSP = (usp) => {
    this.setState({ selectedUSP: usp });
  };

  componentDidMount() {
    const screenWidth = window.innerWidth;
    this.setState({
      url: window.location.hostname,
      screenWidth: screenWidth,
      selectedUSP: screenWidth > 991 ? 2 : 0,
    });
  }

  render() {
    const { url, screenWidth, selectedUSP } = this.state;

    return (
      <PresentationElement
        {...this.props}
        url={url}
        screenWidth={screenWidth}
        selectedUSP={selectedUSP}
        setSelectedUSP={this.setSelectedUSP}
      />
    );
  }
}

const PresentationElement = (props) => {
  return (
    <>
      {/* <!-- Content section 2 --> */}
      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="order-lg-2">
              <Box
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <Title>
                  {props.presentation.title}
                  {/* <br className="d-none d-sm-block d-md-none d-xl-block" /> */}
                </Title>
                <Text>{props.presentation.description}</Text>
                <Box mt={3} pt={3}>
                  <ContentCard
                    className={
                      "d-sm-flex align-items-start justify-content-start " +
                      (0 === props.selectedUSP ? "selected" : "not-selected")
                    }
                    onClick={() => props.setSelectedUSP(0)}
                    mb={2}
                  >
                    <StyledBox mr={4} mb={3}>
                      <img
                        src={iconWebsite}
                        alt=""
                        style={{ width: 40, height: 40 }}
                      />
                    </StyledBox>
                    <Box>
                      <Title variant="card" fontSize="21px">
                        {props.presentation.feature1title}
                      </Title>
                      <Text variant="small">
                        {props.presentation.feature1description}
                      </Text>
                    </Box>
                  </ContentCard>

                  <ContentCard
                    className={
                      "d-sm-flex align-items-start justify-content-start " +
                      (1 === props.selectedUSP ? "selected" : "not-selected")
                    }
                    onClick={() => props.setSelectedUSP(1)}
                    mb={2}
                  >
                    <StyledBox mr={4} mb={3}>
                      <img
                        src={iconApp}
                        alt=""
                        style={{ width: 40, height: 40 }}
                      />
                    </StyledBox>
                    <Box>
                      <Box style={{ display: "flex" }}>
                        <Title variant="card" fontSize="21px">
                          {props.presentation.feature3title}
                        </Title>

                        {/* <StyledButton>
                          <i className="fas fa-star mr-2" />{" "}
                          <FormattedMessage id="new" defaultMessage="New!" />
                        </StyledButton> */}
                      </Box>
                      <Text variant="small">
                        {props.presentation.feature3description}
                      </Text>
                    </Box>
                  </ContentCard>

                  <ContentCard
                    className={
                      "d-sm-flex align-items-start justify-content-start " +
                      (2 === props.selectedUSP ? "selected" : "not-selected")
                    }
                    onClick={() => props.setSelectedUSP(2)}
                  >
                    <StyledBox mr={4} mb={3}>
                      <img
                        src={iconSlideshow}
                        alt=""
                        style={{ width: 40, height: 40 }}
                      />
                    </StyledBox>
                    <Box>
                      <Title variant="card" fontSize="21px">
                        {props.presentation.feature2title}
                      </Title>
                      <Text variant="small">
                        {props.presentation.feature2description}
                      </Text>
                    </Box>
                  </ContentCard>
                </Box>
              </Box>
            </Col>

            <Col lg="6" className="order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="200"
                data-aos-once="true"
                className="mt-4 mt-lg-0"
              >
                <ImageContainer
                  className={0 === props.selectedUSP ? "selected" : ""}
                >
                  <PresentationImage>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: props.presentation.image,
                      }}
                    />
                  </PresentationImage>
                  <BtnContainer
                    className="d-none d-lg-block"
                    style={{ top: "50%", left: "47.5%" }}
                  >
                    <a
                      href={"https://" + props.url + "/live/demo"}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="OpenDemoWebsite"
                    >
                      <Button>
                        <i
                          className="fas fa-external-link-alt"
                          style={{ marginRight: 10 }}
                        />
                        {props.presentation.demo_website}
                      </Button>
                    </a>
                  </BtnContainer>
                  <a
                    href={"https://" + props.url + "/live/demo"}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="OpenDemoWebsite"
                  >
                    <Button
                      variant="outline"
                      color="dark"
                      className="d-block d-lg-none d-flex mx-auto mt-4"
                      style={{ padding: "0.4em 0.75em" }}
                    >
                      <i
                        className="fas fa-external-link-alt"
                        style={{ marginRight: 10 }}
                      />
                      {props.presentation.demo_website}
                    </Button>
                  </a>
                </ImageContainer>

                <ImageContainer
                  className={1 === props.selectedUSP ? "selected" : ""}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {1 === props.selectedUSP && (
                      <div style={{ flex: 1 }}>
                        <TopCard
                          bg="secondary"
                          p="18px"
                          mb={3}
                          borderRadius={8}
                          className="align-items-center d-block d-lg-none d-xl-block"
                          data-aos="fade-right"
                          data-aos-duration="750"
                          data-aos-once="true"
                          data-aos-delay="300"
                        >
                          <Box
                            width="30px"
                            minWidth="30px"
                            height="30px"
                            minHeight="30px"
                            bg="rgb(255,255,255,0.3)"
                            color="light"
                            borderRadius="50%"
                            className="d-none d-md-flex align-items-center justify-content-center"
                            mb={3}
                          >
                            <i className="fas fa-star"></i>
                          </Box>
                          <Text
                            color="light"
                            fontSize={[0, 2]}
                            lineHeight="1.25"
                          >
                            {props.presentation.feature3usp1}
                          </Text>
                        </TopCard>
                        <TopCard
                          bg="secondary"
                          p="18px"
                          mb={3}
                          borderRadius={8}
                          className="align-items-center d-block d-lg-none d-xl-block"
                          data-aos="fade-right"
                          data-aos-duration="750"
                          data-aos-once="true"
                          data-aos-delay="400"
                        >
                          <Box
                            width="30px"
                            minWidth="30px"
                            height="30px"
                            minHeight="30px"
                            bg="rgb(255,255,255,0.3)"
                            color="light"
                            borderRadius="50%"
                            className="d-none d-md-flex align-items-center justify-content-center"
                            mb={3}
                          >
                            <i class="fas fa-bell"></i>
                          </Box>
                          <Text
                            color="light"
                            fontSize={[0, 2]}
                            lineHeight="1.25"
                          >
                            {props.presentation.feature3usp2}
                          </Text>
                        </TopCard>
                        <TopCard
                          bg="secondary"
                          p="18px"
                          borderRadius={8}
                          className="align-items-center d-block d-lg-none d-xl-block"
                          data-aos="fade-right"
                          data-aos-duration="750"
                          data-aos-once="true"
                          data-aos-delay="500"
                        >
                          <Box
                            width="30px"
                            minWidth="30px"
                            height="30px"
                            minHeight="30px"
                            bg="rgb(255,255,255,0.3)"
                            color="light"
                            borderRadius="50%"
                            className="d-none d-md-flex align-items-center justify-content-center"
                            mb={3}
                          >
                            <svg
                              style={{ width: 18, height: 18 }}
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M8.5,9A6.5,6.5 0 0,0 2,15.5A6.5,6.5 0 0,0 8.5,22A6.5,6.5 0 0,0 15,15.5V13.91L22,12V9H11V11H9V9H8.5M11,2V7H9V2H11M6.35,7.28C5.68,7.44 5.04,7.68 4.43,8L2.14,4.88L3.76,3.7L6.35,7.28M17.86,4.88L16.32,7H13.85L16.24,3.7L17.86,4.88Z"
                              />
                            </svg>
                          </Box>
                          <Text
                            color="light"
                            fontSize={[0, 2]}
                            lineHeight="1.25"
                          >
                            {props.presentation.feature3usp3}
                          </Text>
                        </TopCard>
                      </div>
                    )}
                    <PresentationImageVertical className="ml-2 ml-lg-4">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: props.presentation.image3,
                        }}
                      />
                    </PresentationImageVertical>
                  </div>

                  <BtnContainer
                    className="d-none d-lg-block"
                    style={{ top: "45%", left: "64.5%" }}
                  >
                    <a
                      href={
                        "https://play.google.com/store/apps/details?id=com.tournifyapp"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      id="OpenDemoWebsite"
                    >
                      <Button>
                        <i
                          className="fab fa-android"
                          style={{ marginRight: 10 }}
                        />
                        {props.presentation.download_android}
                      </Button>
                    </a>
                  </BtnContainer>
                  <BtnContainer
                    className="d-none d-lg-block"
                    style={{ top: "57%", left: "64.5%" }}
                  >
                    <a
                      href={
                        "https://apps.apple.com/nl/app/tournify/id1504284547"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      id="OpenDemoWebsite"
                    >
                      <Button>
                        <i
                          className="fab fa-apple"
                          style={{ marginRight: 10 }}
                        />
                        {props.presentation.download_ios}
                      </Button>
                    </a>
                  </BtnContainer>
                  <a
                    href={"https://play.google.com/store"}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="OpenDemoWebsite"
                  >
                    <Button
                      variant="outline"
                      color="dark"
                      className="d-block d-lg-none d-flex mx-auto mt-4"
                      style={{ padding: "0.4em 0.75em" }}
                    >
                      <i
                        className="fab fa-android"
                        style={{ marginRight: 10 }}
                      />
                      {props.presentation.download_android}
                    </Button>
                  </a>
                  <a
                    href={"https://www.apple.com/nl/ios/app-store/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="OpenDemoWebsite"
                  >
                    <Button
                      variant="outline"
                      color="dark"
                      className="d-block d-lg-none d-flex mx-auto mt-4"
                      style={{ padding: "0.4em 0.75em" }}
                    >
                      <i className="fab fa-apple" style={{ marginRight: 10 }} />
                      {props.presentation.download_ios}
                    </Button>
                  </a>
                </ImageContainer>

                <ImageContainer
                  className={2 === props.selectedUSP ? "selected" : ""}
                >
                  <PresentationImage>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: props.presentation.image2,
                      }}
                    />
                  </PresentationImage>
                  <BtnContainer
                    className="d-none d-lg-block"
                    style={{ top: "50%", left: "47.5%" }}
                  >
                    <a
                      href={"https://" + props.url + "/live/demo/present"}
                      target="_blank"
                      rel="noopener noreferrer"
                      id="OpenDemoWebsite"
                    >
                      <Button>
                        <i
                          className="fas fa-external-link-alt"
                          style={{ marginRight: 10 }}
                        />
                        {props.presentation.demo_slideshow}
                      </Button>
                    </a>
                  </BtnContainer>
                </ImageContainer>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Presentation;
