import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Text, Box } from "../../components/Core";
import { device } from "../../utils";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

const ImgContainer = styled.div`
  margin-top: 20px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(40%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(20%, -50%);
  }
`;

const AppShowcase = styled.div`
  box-shadow: ${({ theme }) => `0 12px 84px ${theme.colors.shadow}`};
  border-radius: 15px;
  overflow: hidden;
  width: 860px;
  transition: all 1s ease;
`;

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
  position: relative;
  padding: 20px;
  margin-bottom: 10px;
  transition: background 0.5s ease;
  border-radius: 15px;
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 15px;
    min-width: 30px;
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.light};
    font-size: 21px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
  &.not-selected:hover {
    cursor: pointer;
    background: #fff;
  }
  &.selected {
    background: #fff;
  }
  h4 {
    padding-left: 40px;
  }
  p {
    padding-left: 40px;
  }
`;

const ImageContainer = styled.div`
  opacity: 0;
  display: none;
  transition: opacity 1s ease-in-out;
  &.selected {
    opacity: 1;
    display: block;
  }
`

const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
  return (
    <WidgetStyled {...rest}>
      <Title variant="card" mb={1}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </WidgetStyled>
  );
};

const Content1 = (props) => {
  const [selectedUSP, setSelectedUSP] = useState(2);

  return (
  <>
    {/* <!-- Content section 1 --> */}
    <Box
      bg="#F7F7FB"
      className="position-relative"
      pt={["50px", "50px", "50px", "64px"]}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg="6">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>{props.mainpitch}</Title>
                <Text mb={4}>{props.description}</Text>
                  
                <WidgetContainer mt={3}>
                {props.usps.map((usp, i) => (
                  <Widget key={i} title={usp.usp} mb={4} className={i === selectedUSP ? "selected" : "not-selected"} onClick={() => setSelectedUSP(i)}>
                    {usp.description}
                  </Widget>
                ))}
                </WidgetContainer>
              </div>
            </div>
          </Col>
          <Col lg="6" className=" position-static">
            <ImgContainer className=" pl-lg-5" style={{ zIndex: 11 }}>
              <AppShowcase
                className="img-fluid"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
              >

              {props.usps.map((usp, i) => (
                <ImageContainer className={i === selectedUSP ? "selected" : ""}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: usp.image,
                  }}
                />
                </ImageContainer>
              ))}
              </AppShowcase>
            </ImgContainer>
          </Col>
        </Row>
      </Container>
    </Box>
  </>
)};

export default Content1;
