import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import BlogRoll from "../../components/BlogRoll";
import { Link } from "gatsby";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import { langKeyDefault } from "../../../buildVariables";

const ReadMore = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: center;
`;

const BlogSection = (props) => (
  <>
    <Section bg="#f7f7fb" borderBottom="1px solid #EAE9F2;">
      <Container>
        <Row className="justify-content-md-center">
          <Col md="9" lg="8" className="text-md-center">
            <Box className="text-md-center">
              <Title>{props.heading}</Title>
            </Box>
            <Text mb={4}>{props.subheading}</Text>
          </Col>
        </Row>

        <Row>
          <BlogRoll cta={props.cta} blogs={props.blogs} />
        </Row>
        <ReadMore className="text-decoration-none">
          <Link
            to={
              "/" +
              (props.langKey === langKeyDefault ? "" : props.langKey + "/") +
              "blog"
            }
          >
            <Button variant="outline" color="dark">
              {props.readmore}
            </Button>
          </Link>
        </ReadMore>
      </Container>
    </Section>
  </>
);

export default BlogSection;
